@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl.woff2') format('woff2'),
       url('./fonts/SuisseIntl.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SuisseIntl-light';
  src: url('./fonts/SuisseIntl_light.woff2') format('woff2'),
       url('./fonts/SuisseIntl_light.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'SuisseIntl-Italic';
  src: url('./fonts/SuisseIntl-Italic.woff') format('woff'),
       url('./fonts/SuisseIntl-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.body {
  background-color: #FAFAFA;
}

.btn {
  @apply px-4 py-2 bg-blue-600 text-white font-bold rounded hover:bg-blue-500;
}

@layer base {
  :root {
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}
